/* scrollbar */
@import 'simplebar-react/dist/simplebar.min.css';

.code-snippet {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
}

.snippet-header {
  font-weight: bold;
  margin-bottom: 10px;
}

.code {
  overflow-x: auto;
}

.language-html {
  color: #c7254e;
}

.chart {
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
}

.chart-header {
  font-weight: bold;
  margin-bottom: 10px;
}

.chart-content {
  max-width: 500px; /* Adjust as needed */
  overflow-x: auto;
}
